<template>
  <div>
    <!-- GST Number -->
    <div class="col-12 w-100 mt-2 mb-2">
      <ValidationObserver ref="orgForm" >
        <b-form>
          <div class="col-md-12 mb-3 mt-3 p-0">
            <h5>{{ cvGSTNumberLabel }} <label class="primary-color font-size-16">*</label></h5>
            <ValidationProvider name="GST Number" rules="required" v-slot="{ errors }">
              <input v-model="vmOrganisationFormData.bill_gstin" type="text" class="form-control" :placeholder="cvGSTNumberLabel" required />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
            <br />
          </div>
        </b-form>
      </ValidationObserver>
    </div>
    <!-- GST Number -->

     <!-- Pancard Number -->
    <div class="form-row col-12">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 w-100 mb-2">
        <ValidationObserver ref="orgForm" >
          <b-form>
            <div class="col-md-12 mb-3 mt-3 p-0">
              <h5>{{ cvPancardNumberLabel }} <label class="primary-color font-size-16">*</label></h5>
              <ValidationProvider name="Pancard Number" rules="required" v-slot="{ errors }">
                <input v-model="vmOrganisationFormData.org_pancard_no" type="text" class="form-control" :placeholder="cvPancardNumberLabel" required />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-12 mb-3 mt-3 p-0">
              <!-- Upload Documents -->
              <label for="excAttachmentFileInput" class="pointer primary-color mt-2">(Click here to upload)&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-upload"></i></label><label class="primary-color font-size-16">*</label>
              <input type="file" :accept="allowedExtensions" class="dontShow" id="excAttachmentFileInput" @change="onExtraCurricularAttatachmentChange($event, vmOrganisationFormData, 'excAttachmentFileInput')"><br>
              <div class="col-md-12">
                <template v-if="vmOrganisationFormData.org_pancard_attach && vmOrganisationFormData.org_pancard_attach.length">
                  <div v-for="(attachment, index) of vmOrganisationFormData.org_pancard_attach" :key="index">
                    <div class="attachmentCol mr-4">
                      <span style="word-wrap: break-word;" class="pointer" @click="openFile(attachment.url)">{{ index+1 }}.&nbsp;&nbsp;&nbsp;&nbsp;{{ attachment.fileName ? attachment.fileName.split("/").pop() : attachment.url.split("/").pop() }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                      <img class="pointer" v-if="attachment.content" :src="pdfLogo" @click="openFile(attachment.url)">
                      <img v-else-if="attachment.fileName" :src="pdfLogo">
                      <img v-else alt="not found">
                      <span class="addIcon extraCloseIcon" @click="vmOrganisationFormData.org_pancard_attach.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
                    </div>
                  </div>
                </template>
              </div><!-- Upload Documents -->
            </div>
          </b-form>
        </ValidationObserver>
      </div><!-- Pancard Number -->
    </div>

    <button v-if="vmOrganisationFormData.org_pancard_attach" @click="saveSaasOrgDetails" type="button" class="d-flex btn-primary border-0 px-3 py-2 rounded mt-2 mb-4 ml-1" >
      Save Tax Info
    </button>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </div>
</template>

<script>

import { OrgSaas } from "../../../FackApi/api/orgSaas.js"
import { socialvue } from "../../../config/pluginInit"
import pdfLogo from "../../../assets_gide/img/logo/pdfLogo.png"
import { ValidationProvider } from "vee-validate"

export default {
  name: "OrgSaasTaxDocuments",
  component: {
    ValidationProvider
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propModuleObjId: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      title: "OrgSaas Tax Document",
      vmOrganisationFormData: {},
      cvGSTNumberLabel: "GST/VAT/Tax Number",
      cvOrgAttachmentLabel: "Upload Copy of PAN",
      pdfLogo: pdfLogo,
      allowedExtensions: "application/pdf,image/png,image/jpg,image/jpeg,image/webp",
      cvPancardNumberLabel: "PAN Card",
      cvOrgId: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Tax Info"
    }
  },
  async mounted () {
    this.cvOrgId = this.propModuleObjId ? this.propModuleObjId : this.$route.params.org_id

    this.orgSaasView()
    socialvue.index()
  },
  methods: {
    /**
     * orgSaasView
     */
    async orgSaasView () {
      try {
        let orgSaasView = await OrgSaas.orgSaasOrganisationView(this, this.cvOrgId)
        if (!orgSaasView.resp_status) {
          this.toastVariant = "danger"
          this.toastMsg = orgSaasView.resp_msg
          this.showToast = true
          return
        }
        this.vmOrganisationFormData = orgSaasView.resp_data.data.org_data
        this.vmOrganisationFormData.org_pancard_attach = JSON.parse(this.vmOrganisationFormData.org_pancard_attach)

        if (orgSaasView.resp_data.data.org_admin_bill_details) {
          this.vmOrganisationFormData.bill_gstin = orgSaasView.resp_data.data.org_admin_bill_details.bill_gstin
        }
        if (orgSaasView.resp_data.data.org_data) {
          this.vmOrganisationFormData.org_pancard_no = orgSaasView.resp_data.data.org_data.org_pancard_no
        }
      }
      catch (err) {
        console.error("Exception in orgSaasView() and err: ", err)
      }
    },
    /**
     * saveSaasOrgDetails
      */
    async saveSaasOrgDetails () {
      try {
        if (this.oldDomain != this.vmOrganisationFormData.org_domain) {
          this.vmOrganisationFormData.org_domain_is_validated = "NOT_VALIDATED"
        }
        let saveOrgSaasResp = await OrgSaas.orgSaasOrganisationEdit(this, this.vmOrganisationFormData)
        this.toastMsg = saveOrgSaasResp.resp_msg
        this.toastVariant = saveOrgSaasResp.resp_status ? "success" : "danger"
        this.showToast = true

        if (this.propOpenedInModal) {
          this.$emit("emitTaxInfoCloseEditModal", false)
        }
      }
      catch (err) {
        console.error("Exception in saveSaasOrgDetails() and err: ", err)
      }
    },
    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }

      window.open(url, "_blank")
    },
    /**
     * onExtraCurricularAttatachmentChange
     */
    onExtraCurricularAttatachmentChange (event, extraCurricular, inputId) {
      const fileList = event.target.files

      if (fileList && fileList.length) {
        for (let file of fileList) {
          // Allowed file types
          let allowedExtensions = this.allowedExtensions.split(",")

          let maxSize = 5 * 1024 * 1024 // 5MB limit

          if (!allowedExtensions.includes(file.type)) {
            this.toastMsg = "Invalid file type. Please upload a supported file."
            this.toastVariant = "danger"
            this.showToast = true
            continue
          }

          if (file.size > maxSize) {
            this.toastMsg = "File size exceeds the 5MB limit."
            this.toastVariant = "danger"
            this.showToast = true
            continue
          }

          // Read file and store in extraCurricular
          let reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = async (e) => {
            file.url = e.currentTarget.result
            file.fileName = file.name

            if (!extraCurricular.org_pancard_attach) {
              extraCurricular.org_pancard_attach = []
            }

            if (typeof extraCurricular.org_pancard_attach === "string") {
              extraCurricular.org_pancard_attach = JSON.parse(extraCurricular.org_pancard_attach)
            }

            extraCurricular.org_pancard_attach.unshift(file)

            // Reset input field
            const ele = document.getElementById(inputId)
            if (ele) {
              ele.value = ""
            }
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
  .attachmentCol {
    img {
      height: 32px;
      width: 32px;
      object-fit: contain;
      border: 2px solid aliceblue;
      margin-bottom: 6px;
    }
  }
  .extraCloseIcon {
    font-size: 18px;
    position: relative;
    top: -15px;
    cursor: pointer;
    z-index: 99;
    margin-left: -9px;
    color: #e5252c;
  }
</style>
